window.Element.prototype.qsa = function querySelectorAllToArray( selector ){
  const selection = this.querySelectorAll( selector );

  return Array.prototype.slice.call( selection );
};

Document.prototype.qsa = function querySelectorAllToArray( selector ){
  const selection = this.querySelectorAll( selector );

  return Array.prototype.slice.call( selection );
};
