import sliders from "./sliders";
import misc from './misc';

/**
 * Toggle
 * @constructor
 */
class Toggle {
  /**
   * Inits the module with necessary functions
   * @method
   **/
  constructor() {
    this._toggleHandler = this._toggleHandler.bind(this);
    this.closeAll = this.closeAll.bind(this);
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);

    this._bind();
  }

  _bind() {
    document.body.listen("click", "[data-toggle]", this._toggleHandler);
    document.body.listen("change", "select[data-toggler]", this._toggleHandler);
  }

  _toggleHandler(e) {
    const trigger = e.currentTarget,
      toggler = trigger.hasAttribute("data-toggler");

    let data = {
      id: toggler ? trigger.querySelectorAll("option") [trigger.selectedIndex].getAttribute("aria-controls") : trigger.getAttribute("aria-controls")
    };

    try {
      let attr = toggler ? JSON.parse(trigger.dataset["toggler"]) : JSON.parse(trigger.dataset["toggle"]); data = Object.assign(attr, data);
    }
    catch (e) {}

    if ( toggler && trigger.querySelectorAll("option")[trigger.selectedIndex].getAttribute("aria-controls") === "reset" ) {
      data.id = trigger.closest("[data-tabs]").querySelector('[aria-hidden="false"]').id;
      this.close(data);
      return;
    }

    if ( toggler && trigger.querySelectorAll("option")[trigger.selectedIndex].hasAttribute("data-locate") ) {
      misc.locate( trigger.form );
    }

    if ( (!toggler && trigger.getAttribute("aria-expanded") == "false") || (toggler && trigger.querySelectorAll("option")[trigger.selectedIndex].getAttribute("aria-expanded") == "false") ) {
      this.open(data);
    }
    else if ( !data.tabs || (data.tabs && trigger.getAttribute("aria-expanded") == "false") ) {
      this.close(data);
    }
  }

  open(data) {
    const triggers = document.qsa('[aria-controls="' + data.id + '"]'),
      targets = document.qsa('[data-toggle-targets="' + data.id + '"]').length > 0 ? document.qsa('[data-toggle-targets="' + data.id + '"]') : [document.getElementById(data.id)],
      otherTab = data.tabs ? triggers[0] .closest("[data-tabs]") .querySelector('[aria-expanded="true"]') : null;

    targets.forEach(target => {
      target.setAttribute("aria-hidden", false);
      target.classList.remove("toggle-on");

      if (data.slider) {
        sliders.init(target);
      }
      if( target.querySelector('[data-inactive]') ){
        target.qsa('[data-inactive]').forEach( (input) =>{
          input.disabled = false;
        });
      }
    });
    triggers.forEach(trigger => {
      trigger.setAttribute("aria-expanded", true);
    });

    if (data.body) {
      document.documentElement.classList.add(data.body);
    }

    if (data.tabs && otherTab) {
      this.close({ id: otherTab.getAttribute("aria-controls") });
    }
    else if (!data.tabs && !data.keep) {
      document.body.addEventListener("click", this.closeAll);
    }
  }

  close(data) {
    const triggers = document.qsa('[aria-controls="' + data.id + '"]'),
      targets = document.qsa('[data-toggle-targets="' + data.id + '"]').length > 0 ? document.qsa('[data-toggle-targets="' + data.id + '"]') : [document.getElementById(data.id)];

    targets.forEach(target => {
      target.setAttribute("aria-hidden", true);
      target.classList.remove("toggle-on");
      if( target.querySelector('[data-inactive]') ){
        target.qsa('[data-inactive]').forEach( (input) =>{
          input.disabled = true;
        });
      }
    });
    triggers.forEach(trigger => {
      trigger.setAttribute("aria-expanded", false);
    });

    document.body.removeEventListener("click", this.closeAll);

    if (data.body) {
      document.documentElement.classList.remove(data.body);
    }
  }

  closeAll(e) {
    if (e.target.closest('[aria-hidden="false"]')) {
      return;
    }

    const triggers = document.qsa('[aria-expanded="true"]');

    triggers.forEach(trigger => {
      let data = {
        id: trigger.getAttribute("aria-controls")
      };

      try {
        data = Object.assign(JSON.parse(trigger.dataset["toggle"]), data);
      }
      catch (e) {}

      if( data.tabs ){
        return;
      }

      this.close(data);
      document.body.removeEventListener("click", this.closeAll);
    });
  }
}

export default new Toggle();
