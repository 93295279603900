// polyfills
import "core-js/es6/symbol";
import smoothscroll from 'smoothscroll-polyfill';
import './polyfills/closest.js';
import './polyfills/object-assign.js';
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';
import 'formdata-polyfill';


// Helpers
import './helpers/listener';
import './helpers/eventDelegation';
import './helpers/qsa-array';
import './helpers/index';
import './polyfills/object-fit.js';

// Tools
import ps from 'pubsub-js';

// Modules
import './modules/sliders';
import './modules/select';
import './modules/toggle';
import './modules/gallery';
import './modules/range';
import './modules/misc';


smoothscroll.polyfill();

window.locale = navigator.languages ? navigator.languages[0] : ( navigator.language ? navigator.language : document.documentElement.dataset.locale );

// Trigger app start custom event
ps.publish( 'app.start' );
