import ps from 'pubsub-js';
import fetcher from '@switch-company/fetcher';
import formUtils from '@switch-company/form-utils';
import sliders from './sliders';
import select from './select';
import toggle from './toggle';

/**
 * Misc
 * @constructor
 * @requires pubsub-js
 * @requires fetcher
 * @requires form-utils
 */
class Misc {

  /**
   * Inits the module with necessary functions
   * @method
  **/
  constructor() {

    this._storageHandler = this._storageHandler.bind(this);
    this._fixHeader = this._fixHeader.bind(this);
    this._scroll = this._scroll.bind(this);
    this._submit = this._submit.bind(this);
    this._load = this._load.bind(this);
    this._popinVideo = this._popinVideo.bind(this);
    this._bind = this._bind.bind(this);

    this._bind();
    this._fixHeader();

  }

  _bind() {

    document.body.listen('click', '[data-storage]', this._storageHandler);
    document.body.listen('click', '[data-scroll]', this._scroll);
    document.body.listen('change', '[data-submit]', this._submit);
    document.body.listen('click', '[data-load-more]', this._load);
    document.body.listen('click', 'a[data-load], button[data-load]', this._load);
    document.body.listen('change', 'select[data-load]', this._load);
    document.body.listen('submit', 'form[data-load]', this._load);
    document.body.listen('click', '[data-video]:not([data-thumb])', this._popinVideo);

    ps.subscribe('search', this._submit );
    ps.subscribe('searchafter', this._submit );

    if( document.cookie.indexOf('legalAccepted') !== -1 && document.getElementById('cookies') ){
      document.getElementById('cookies').parentNode.removeChild(document.getElementById('cookies'));
    }

  }

  locate( form ){

    if( navigator.geolocation ) {

      const prevent = function(e){
        e.preventDefault();
        e.stopPropagation();
      }
      let pos = {};

      // disable form
      form.classList.add('loading');
      form.addEventListener('submit', prevent);

      // geolocate
      navigator.geolocation.getCurrentPosition(
        // success
        function( position ){
          // console.log( "User accepted the request for Geolocation and it worked:", position );
          window.userLocation = position.coords;
          for( const key in position.coords ){
            pos[key] = position.coords[key];
          }
          document.qsa('[data-position]').forEach( (input) => {
            input.value = JSON.stringify(pos);
          });

          // re-enable
          form.classList.remove('loading');
          form.removeEventListener('submit', prevent);
          ps.publish('search', form);
        },
        // error
        function( error ){
          switch(error.code) {
            case error.PERMISSION_DENIED:
              // console.log( "User denied the request for Geolocation." );
              break;
            case error.POSITION_UNAVAILABLE:
              // console.log( "Location information is unavailable." );
              break;
            case error.TIMEOUT:
              // console.log( "The request to get user location timed out." );
              break;
            case error.UNKNOWN_ERROR:
              // console.log( "An unknown error occurred." );
              break;
          }

          form.qsa('[data-locate]').forEach((option)=>{
            let parentSelect = option.closest('select');
            option.setAttribute('disabled', true);
            parentSelect.querySelector('option:not([disabled])').selected = 'selected';
            select.change( option.closest('select') );
            toggle.close({id: option.getAttribute('aria-controls')});
          });

          // re-enable
          form.classList.remove('loading');
          form.removeEventListener('submit', prevent);
        }
      );
    }

  }

  _scroll( e ){

    const trigger = e.originalEvent ? e.currentTarget : e,
          smooth = { behavior: 'smooth' },
          scrollData = trigger.dataset && trigger.dataset.scroll ? Object.assign(JSON.parse(trigger.dataset.scroll), smooth) : smooth;

    let id;

    if( e.originalEvent ){
      e.preventDefault();

      if( trigger.href ){
        id = trigger.getAttribute('href');
      }
    }
    else{
      id = trigger.href;
    }

    if( !("top" in scrollData) && trigger.href ){
      if( !document.querySelector( id ) ){
        console.log("Aucun élément avec l'ID "+id);
        return;
      }
      // document.querySelector(trigger.getAttribute('href')).scrollIntoView(smooth);
      scrollData.top = document.querySelector( id ).offsetTop - ( document.querySelector('header[role="banner"]') ? document.querySelector('header[role="banner"]').offsetHeight : 0) - 10;
    }
    window.scroll( scrollData );

  }

  _popinVideo( e ){

    const trigger = e.currentTarget,
          video = window.config.conf.templates.video.replace('${src}', trigger.dataset.video);

    document.body.insertAdjacentHTML('beforeend', `<div class="popin"><button class="bt-close" data-close></button><div class="video-wp">${video}</div></div>`);

    document.querySelector('.popin').addEventListener('click', function(e){
      if( !e.currentTarget.closest('[class*="-wp"]') ){
        const popin = e.currentTarget.closest('.popin');
        popin.parentNode.removeChild(popin);
      }
    });

  }

  _storageHandler( e ) {

    const trigger = e.currentTarget,
          data = trigger.dataset.storage;

    document.cookie += data;

  }

  _fixHeader(){
    const menu = document.querySelector('header[role="banner"].fixable'),
          sticky = !!window.CSS && window.CSS.supports('position','sticky');

    if( !menu || window.istouch ){
      return;
    }

    let isAdded = false,
        menuPosition = menu.getBoundingClientRect();

    window.addEventListener('scroll', function() {
      menuPosition = menu.getBoundingClientRect();
      if( 0 >= menuPosition.top && !isAdded ){
        if( !sticky ){
          menu.style.height = menu.offsetHeight+'px';
          menu.classList.add('fixed');
        }
        menu.classList.add('fix');
        isAdded = true;
      }
      else if( 0 < menuPosition.top && isAdded ){
        if( !sticky ){
          menu.style.height = '';
          menu.classList.remove('fixed');
        }
        menu.classList.remove('fix');
        isAdded = false;
      }
    });

  }

  _load( e ){

    e.preventDefault();

    const trigger = e.currentTarget,
          mode = trigger.dataset.loadMore ? 'loadMore' : 'load';

    let data = JSON.parse(trigger.dataset[mode]);

    data.params = data.params || {};

    const target = document.querySelector('[data-load-content="'+ data.target +'"');

    if( trigger.form || trigger.hasAttribute('action') ){
      let elt = trigger.hasAttribute('action') ? trigger : trigger.form;
      data.params = Object.assign( formUtils.toJSON(elt), data.params );

    }

    if( trigger.dataset.params ){
      data.params = Object.assign( JSON.parse(trigger.dataset.params), data.params );
    }

    for( let key in data.params ){
      if( data.params[key].length == 0 ){
        delete data.params[key];
      }
    }

    data.url = trigger.closest('form') ? trigger.closest('form').action : (data.url || trigger.href);

    target.classList.add('loading');

    fetcher.get( data.url, { data: data.params } )
      .then( (body) => {

        if( mode == 'load' ){
          target.innerHTML = body.data.html;
        }
        else{
          target.insertAdjacentHTML('beforeend', body.data.html);
        }
        if( body.data.endReached ){
          trigger.classList.add('disabled');
        }
        if( data.slider ){
          const sliderParams = JSON.parse( target.querySelector('[data-sliderconfig]').dataset.sliderconfig );

          if( sliderParams.slidesPerView < target.querySelectorAll('.swiper-slide').length ){
            sliders.init(target);
            target.classList.remove('unslidered');
          }
          else{
            target.classList.add('unslidered');
          }

        }
        if( body.data.params ){
          trigger.dataset.params = body.data.params;
        }
        if( data.scroll ){
          this._scroll({href: '#'+target.id})
        }
        target.classList.remove('loading');
      });

  }

  _submit( e, elt ){

    console.log("submit:",e);


    if( e.target && e.target.dataset.populate ){
      return;
    }

    window.requestAnimationFrame(function(){

      // do not submit if data-nosubmit option (case of location) -> triggered by this.locate
      if( e.target && e.target.selectedIndex && e.target.querySelectorAll('option')[e.target.selectedIndex].hasAttribute('data-nosubmit') ){
        return;
      }

      const trigger = elt || e.currentTarget;
      let data = trigger.dataset.submit ? JSON.parse( trigger.dataset.submit ) : null,
          params = formUtils.toJSON(trigger);

      data.url = data.url ? data.url : trigger.action;

      for( let key in params ){
        if( params[key].length == 0 ){
          delete params[key];
        }
      }

      let request = fetcher.get( data.url, { data: params } );

      if( data.updateText ){
        request.then((body) => {
          document.qsa('[data-text-update="'+ data.updateText +'"]').forEach( (updateElt) =>{
            const bt = updateElt.closest('button');
            updateElt.textContent = body.data.results;

            if( body.data.html ){
              document.querySelector('[data-load-content="'+ JSON.parse(trigger.dataset.load).target +'"]').innerHTML = body.data.html;
              if( body.data.results == 0 && bt ){
                bt.classList.add('no-results');
                bt.setAttribute('disabled', true);
              }
            }
            else if( bt ){
              bt.classList.remove('no-results');
              bt.removeAttribute('disabled');
            }
          });
        });
      }

    })

  }

}

export default new Misc();
